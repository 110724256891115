import React, { useState } from 'react'
import { Image } from 'react-bootstrap'
import ImageCover from '@/components/image-cover'
import ClassName from 'clsx'
import Styles from './style.module.scss'
import useLang from '@/utils/hooks/use-lang'
import { useNavigate } from 'react-router-dom'
import { data as _data, title, contact_us, slogan_title, slogan_desc } from './data.js'

function Item(props) {
  const { active, title } = props

  return <div className={ClassName(Styles.front, active && Styles.active)}>{title}</div>
}

export default function Index() {
  const [activeIndex, setActiveIndex] = useState(0)
  const navigate = useNavigate()
  const [lang] = useLang()
  const data = _data[lang]

  return (
    <div className={Styles.container}>
      <div className={Styles.main}>
        <div className={Styles.header}>
          <div className={Styles.title}>{title[lang]}</div>
          {/* <div className={Styles.slogan}>
            <div className={Styles.slogan_title}>{slogan_title[lang]}</div>
            <div className={Styles.slogan_desc}>{slogan_desc[lang]}</div>
          </div> */}
          <div className={Styles.contact_us} onClick={() => navigate('/contact')}>
            {contact_us[lang]}
          </div>
        </div>
        <div className={Styles.content}>
          <div className={Styles.content_left}>
            <div className={Styles.title}>
              <Image src={data[activeIndex].icon_white} />
              <span>{data[activeIndex].title}</span>
            </div>
            {!!data?.[activeIndex]?.list?.length && (
              <div className={Styles.list}>
                {data[activeIndex].list.map((t, i) => (
                  <div key={i} className={Styles.cell}>
                    {/* <Image src='assets/correct.svg' /> */}
                    <span>{t}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className={Styles.content_right}>
            <ImageCover className={Styles.image_cover} src={data[activeIndex].image} />
          </div>
        </div>
        <div className={Styles.tabs}>
          {data?.map((d, i) => (
            <div className={Styles.tab_item} key={i} onMouseEnter={() => setActiveIndex(i)}>
              <Item {...d} active={activeIndex === i} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
