import Header from '@/components/header'
import Footer from '@/components/footer'
import Styles from './style.module.scss'

export default function PageWrapper({ soul, children }) {
  return (
    <div className={Styles.page_wrapper}>
      <Header soul={soul} />
      <div className={Styles.page_main}>{children}</div>
      <Footer />
    </div>
  )
}
