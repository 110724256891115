import { solution_data, service_center, about_us } from '@/utils/data'

export const data = lang => {
  const solution = solution_data[lang]
  const service = service_center[lang]
  const about = about_us[lang]

  const d = {
    en: [
      {
        title: 'Product',
        path: '/product',
        children: [
          {
            title: 'PCS',
            path: '/',
          },
          {
            title: 'Battery Storage',
            path: '/',
          },
          {
            title: 'ESS',
            path: '/',
          },
          {
            title: 'EV Charging',
            path: '/',
          },
          {
            title: 'EMS & APP',
            path: '/',
          },
        ],
      },
      {
        title: 'Solution',
        path: '',
        children: solution,
      },
      {
        title: 'Resource Center',
        path: '',
        children: service,
      },
      {
        title: 'About Us',
        path: '',
        children: about,
      },
      {
        title: 'News',
        path: '/news',
      },
    ],
    es: [
      {
        title: 'Producto',
        path: '/product',
        children: [
          {
            title: 'Sistema de Conversión de Energía',
            path: '/',
          },
          {
            title: 'Almacenamiento de Baterías',
            path: '/',
          },
          {
            title: 'Sistema de Almacenamiento de Energía',
            path: '/',
          },
          {
            title: 'Carga de Vehículos Eléctricos (EV Charging)',
            path: '/',
          },
          {
            title: 'EMS (Sistema de Gestión de Energía) y Aplicación (APP)',
            path: '/',
          },
        ],
      },
      {
        title: 'Solución',
        path: '',
        children: solution,
      },
      {
        title: 'Centro de Recursos',
        path: '',
        children: service,
      },
      {
        title: 'Sobre Nosotros',
        path: '',
        children: about,
      },
      {
        title: 'Noticias',
        path: '/news',
      },
    ],
    cn: [
      {
        title: '产品',
        path: '/product',
        children: [
          {
            title: '电力控制系统',
            path: '/',
          },
          {
            title: '储能电池柜',
            path: '/',
          },
          {
            title: 'ESS一体机',
            path: '/',
          },
          {
            title: 'EV charger',
            path: '/',
          },
          {
            title: 'EMS & APP',
            path: '/',
          },
        ],
      },
      {
        title: '解决方案',
        path: '/solution',
        children: solution,
      },
      {
        title: '服务中心',
        path: '',
        children: service,
      },
      {
        title: '关于我们',
        path: '',
        children: about,
      },
      {
        title: '新闻中心',
        path: '/news',
      },
    ],
  }

  return d[lang]
}
