import React, { useState } from 'react'
import { Routes, Route } from 'react-router-dom'
import Layout from '@/components/layout'
import Homepage from '@/pages/homepage'
import News from '@/pages/news'
import NewsDetail from '@/pages/news/detail'
import About from '@/pages/about'
import Join from '@/pages/join'
import Contact from '@/pages/contact'
import Solution_1 from '@/pages/solutions/ci-energy-solution'
import Solution_2 from '@/pages/solutions/clouding-service'
import Solution_3 from '@/pages/solutions/design-consulting'
import Solution_4 from '@/pages/solutions/financial-service'
import Service from '@/pages/service-center'
import Service_1 from '@/pages/service-center/download'
import Service_2 from '@/pages/service-center/quality'
import Service_3 from '@/pages/service-center/support'
import Service_4 from '@/pages/service-center/train'
import Service_5 from '@/pages/service-center/after-sale'
import Product from '@/pages/product'
import ProductDetail from '@/pages/product/detail'
import 'bootstrap/dist/css/bootstrap.min.css'
import { ThemeProvider } from 'react-bootstrap'

const LayoutComponents = props => <Layout {...props}></Layout>

export const GlobalContext = React.createContext()

function App() {
  const [globalProps, setGlobalProps] = useState({
    lang: localStorage.lang || 'en',
  })

  return (
    <GlobalContext.Provider value={{ globalProps, setGlobalProps }}>
      <ThemeProvider breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm']} minBreakpoint='sm'>
        <Routes>
          <Route exact path='/' element={<LayoutComponents />}>
            {/* 首页 */}
            <Route exact path='/' element={<Homepage />} />
            {/* 解决方案 */}
            <Route exact path='/solution/ci-energy-solution' element={<Solution_1 />} />
            <Route exact path='/solution/clouding-service' element={<Solution_2 />} />
            <Route exact path='/solution/design-consulting' element={<Solution_3 />} />
            <Route exact path='/solution/financial-service' element={<Solution_4 />} />
            {/* 产品 */}
            <Route exact path='/product' element={<Product />} />
            <Route exact path='/productDetail' element={<ProductDetail />} />
            {/* 服务中心 */}
            <Route exact path='/service/download' element={<Service />} />
            <Route exact path='/service/quality' element={<Service />} />
            <Route exact path='/service/support' element={<Service />} />
            <Route exact path='/service/train' element={<Service />} />
            <Route exact path='/service/afterSale' element={<Service />} />
            {/* 新闻 */}
            <Route exact path='/news' element={<News />} />
            <Route exact path='/news-detail' element={<NewsDetail />} />
            {/* 关于我们 */}
            <Route exact path='/about' element={<About />} />
            <Route exact path='/join' element={<Join />} />
            <Route exact path='/contact' element={<Contact />} />
            {/* 404 */}
            <Route path='*' element={<div>404</div>} />
          </Route>
        </Routes>
      </ThemeProvider>
    </GlobalContext.Provider>
  )
}

export default App
