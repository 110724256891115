import DOMPurify from 'dompurify'
import Styles from './style.module.scss'

export default function Html({ content }) {
  if (!content) return null

  const sanitizeHTML = htmlString => {
    const sanitizedHTML = DOMPurify.sanitize(htmlString)
    return { __html: sanitizedHTML }
  }

  return <div className={Styles.content} dangerouslySetInnerHTML={sanitizeHTML(content)} />
}
