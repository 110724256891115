import { useEffect, useState } from 'react'
import { Carousel, Image } from 'react-bootstrap'
import Styles from './style.module.scss'
import useLang from '@/utils/hooks/use-lang'

const bannerText = {
  en: {
    slogan: 'Ultimate Safety 、Optimal Efficiency、Grid-Integrated Intelligent Operation & Maintenance',
    btnText: 'View Details',
  },
  es: {
    slogan: 'Seguridad máxima, eficiencia óptima, operación y mantenimiento inteligente integrados a la red.',
    btnText: 'Ver Detalles',
  },
  cn: {
    slogan: '极致安全 、 经济高效、电网友好 、智能运维',
    btnText: '查看详情',
  },
}

function UncontrolledExample() {
  const [height, setHeight] = useState(window.innerHeight)
  const [lang] = useLang()

  useEffect(() => {
    window.onresize = () => {
      setHeight(window.innerHeight)
    }
  }, [])

  return (
    <Carousel>
      <Carousel.Item>
        <Image className={Styles.image} style={{ height }} src={`assets/${lang}/home_banner.png`} />
        <Carousel.Caption className={Styles.caption}>
          <h3>{bannerText[lang].slogan}</h3>
          <div>{bannerText[lang].btnText}</div>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  )
}

export default UncontrolledExample
