import { useState, useEffect } from 'react'
import { Pagination } from 'react-bootstrap'
import NewCard from '@/components/news-card'
import Styles from './style.module.scss'
import { get, post } from '@/utils/fetch'
import useLang from '@/utils/hooks/use-lang'

const PageSize = 6

export default function Index({ little }) {
  const [list, setList] = useState([])
  const [total, setTotal] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [lang] = useLang()

  let active = 2
  let items = []
  for (let number = 1; number <= 15; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    )
  }

  const handleRequest = pageIndex => {
    get(`/api/news/${lang}?page=${pageIndex}&pagesize=${little ? 3 : PageSize}`)().then(res => {
      if (res?.code === 0) {
        const l =
          res?.data?.reduce((acc, cur, index) => {
            const groupIndex = Math.floor(index / 3)
            if (!acc[groupIndex]) {
              acc[groupIndex] = []
            }
            acc[groupIndex].push(cur)
            return acc
          }, []) || []

        setList(l)
        setTotal(+res?.count || 0)
      }
    })
  }

  useEffect(() => {
    handleRequest(currentPage)
  }, [currentPage, lang])

  return (
    <>
      <div className={Styles.list}>
        {list.map((d, index) => (
          <div className={Styles.row} key={index}>
            {d.map(i => (
              <NewCard key={i.id} {...i} />
            ))}
          </div>
        ))}
      </div>
      {!!total && !little && (
        <div className={Styles.pagination}>
          <Pagination>
            {Array(Math.ceil(total / PageSize))
              .fill(true)
              .map((_, i) => (
                <Pagination.Item key={i} active={i + 1 === currentPage} onClick={() => setCurrentPage(i + 1)}>
                  {i + 1}
                </Pagination.Item>
              ))}
          </Pagination>
        </div>
      )}
    </>
  )
}
