import { useState, useEffect } from 'react'
import PageWrapper from '@/components/page-wrapper'
import FeatureBanner from '@/components/feature-banner'
import Contact from '@/components/contact'
import { get, post } from '@/utils/fetch'
import Styles from './style.module.scss'
import ClassName from 'clsx'
import List from './list'
import RenderHtml from '@/components/render-html'
import useLang from '@/utils/hooks/use-lang'

const title_text = {
  en: 'Product',
  es: 'Producto',
  cn: '产品中心',
}

export default function Product() {
  const [lang] = useLang()
  const [category, setCategory] = useState([])
  const [list, setList] = useState([])
  const [activeId, setActiveId] = useState(null)

  const getCategory = () => {
    get(`/api/product_group/${lang}`)().then(res => {
      if (res?.code === 0) {
        setCategory(res?.data || [])
        setActiveId(res?.data?.[0]?.id)
      }
    })
  }

  const getCategoryList = id => {
    setActiveId(id)
    get(`/api/product/${id}/${lang}`)().then(res => {
      if (res?.code === 0) {
        setList(res?.data || [])
      }
    })
  }

  useEffect(() => {
    getCategory()
  }, [lang])

  useEffect(() => {
    if (activeId) {
      setList([])
      getCategoryList(activeId)
    }
  }, [activeId, lang])

  return (
    <PageWrapper>
      <FeatureBanner title={title_text[lang]}>
        <div className={Styles.category}>
          {category.map((item, index) => (
            <div
              key={index}
              className={ClassName(Styles.item, activeId === item.id && Styles.active)}
              onClick={() => setActiveId(item.id)}
            >
              {item.name}
            </div>
          ))}
        </div>
      </FeatureBanner>
      <div style={{ flex: 1 }}>
        {list?.length > 1 && <List data={list} />}
        {list?.length === 1 && (
          <>
            <RenderHtml content={list?.[0]?.content} />
            <Contact />
          </>
        )}
      </div>
    </PageWrapper>
  )
}
