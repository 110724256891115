import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import PageWrapper from '@/components/page-wrapper'
import FeatureBanner from '@/components/feature-banner'
import ImageCover from '@/components/image-cover'
import Contact from '@/components/contact'
import { get, post } from '@/utils/fetch'
import Styles from './style.module.scss'
import { useNavigate } from 'react-router-dom'
import RenderHtml from '@/components/render-html'
import useLang from '@/utils/hooks/use-lang'

const productText = {
  en: 'Product',
  es: 'Producto',
  cn: '产品中心',
}

const homeText = {
  en: 'Home',
  es: 'Página de inicio',
  cn: '首页',
}

export default function ProductDetail() {
  const navigate = useNavigate()
  const [data, setData] = useState(null)
  const { search } = useLocation()
  const [lang] = useLang()

  // 获取url hash后面的参数
  const searchParams = new URLSearchParams(search)
  const id = searchParams.get('id')

  const getDetail = product_id => {
    get(`/api/product_detail/${product_id}/${lang}`)().then(res => {
      if (res?.code === 0) {
        setData(res?.data || null)
      }
    })
  }

  useEffect(() => {
    if (id) getDetail(id)
  }, [id])

  return (
    <PageWrapper>
      <FeatureBanner module='Product' backgroundImage='product_detail_banner'>
        {!!data && (
          <div className={Styles.product_header}>
            <div className={Styles.header_main}>
              <div className={Styles.title}>{data.title}</div>
              <div className={Styles.breadcrumbs}>
                <span onClick={() => navigate('/')}>{homeText[lang]}</span> &gt; {productText[lang]} &gt; {data.title}
              </div>
            </div>
            <ImageCover className={Styles.image_cover} src={`image/${data.image}`} />
          </div>
        )}
      </FeatureBanner>
      <div style={{ flex: 1 }}>
        <RenderHtml content={data?.content} />
      </div>
      <Contact />
    </PageWrapper>
  )
}
