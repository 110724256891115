import { useLayoutEffect } from 'react'

export default function UseScrollTop() {
  useLayoutEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant',
    })
  })
}
