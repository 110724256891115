import React, { useState } from 'react'
import { Image } from 'react-bootstrap'
import ClassName from 'clsx'
import Styles from './style.module.scss'

export default function Index({ title, data }) {
  const [activeIndex, setActiveIndex] = useState(0)

  return (
    <div className={Styles.container}>
      <div className={Styles.title}>{title}</div>
      <div className={Styles.content} style={{ backgroundImage: `url(${data[activeIndex].image})` }}>
        <div className={ClassName(Styles.tabs, Styles.sticky)}>
          {data?.map((d, i) => (
            <div
              className={ClassName(Styles.tab_item, activeIndex === i && Styles.active)}
              key={i}
              onMouseEnter={() => setActiveIndex(i)}
            >
              {d.title}
            </div>
          ))}
          <div className={Styles.tab_item} style={{ visibility: 'hidden', height: 0 }} />
        </div>
        <div className={Styles.detail}>
          {!!data?.[activeIndex]?.list?.length && (
            <div className={Styles.list}>
              {data[activeIndex].list.map((t, i) => {
                if (t?.label) {
                  return (
                    <div key={i} className={Styles.row_wrapper}>
                      <div className={Styles.cell}>
                        {i === 0 && <Image className={Styles.title_icon} src='assets/application.svg' />}
                        {i === 1 && <Image className={Styles.title_icon} src='assets/benefit.svg' />}
                        <div className={Styles.label}>{t.label}</div>
                      </div>
                      {!!t?.content?.length &&
                        t.content.map((c, j) => (
                          <div key={j} className={Styles.cell}>
                            {i === 0 && <Image className={Styles.circle} src='assets/ellipse.svg' />}
                            {i === 1 && <Image src='assets/correct.svg' />}
                            <div>{c}</div>
                          </div>
                        ))}
                    </div>
                  )
                }
                return (
                  <div key={i} className={Styles.cell}>
                    <Image src='assets/correct.svg' />
                    <div>{t}</div>
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
