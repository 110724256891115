import PageWrapper from '@/components/page-wrapper'
import Carousel from './carousel'
import Advantage from '@/components/advantage-dark'
import Video from './video-dark'
import Solution from './solution-dark'
// import News from './news'
import News from './news-swiper'
import About from './about'

export default function Index() {
  return (
    <PageWrapper soul>
      <Carousel />
      <Video />
      <Advantage />
      <Solution />
      <News />
      <About />
    </PageWrapper>
  )
}
