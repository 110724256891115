import React from 'react'
import ImageCover from '@/components/image-cover'
import Styles from './style.module.scss'
import { useNavigate } from 'react-router-dom'
import useLang from '@/utils/hooks/use-lang'

export default function Index(props) {
  const navigate = useNavigate()
  const [lang] = useLang()
  // onClick={() => navigate('/about')}

  return (
    <div className={Styles.container}>
      {/* <ImageCover className={Styles.image_cover} src={`assets/${lang}/about_banner.png`} /> */}
      <img style={{ width: '100%' }} src={`assets/${lang}/about_banner.png`} alt='image-map' useMap='#image-map' />
      <map name='image-map' id='image-map'>
        <area shape='rect' coords='858,596,205,60' style={{ cursor: 'pointer' }} onClick={() => navigate('/about')} />
      </map>
    </div>
  )
}
