import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import DOMPurify from 'dompurify'
import PageWrapper from '@/components/page-wrapper'
import FeatureBanner from '@/components/feature-banner'
import { get, post } from '@/utils/fetch'
import Styles from './style.module.scss'
import useLang from '@/utils/hooks/use-lang'

export default function Detail() {
  const { search } = useLocation()
  const [lang] = useLang()
  const [title, setTitle] = useState()
  const [date, setDate] = useState()
  const [htmlString, setHtmlString] = useState([])
  const searchParams = new URLSearchParams(search)
  const id = searchParams.get('id')

  const handleRequest = id => {
    get(`/api/new/${id}/${lang}`)().then(res => {
      if (res?.code === 0) {
        setTitle(res?.data?.title || '')
        setDate(res?.data?.date || '')
        setHtmlString(res?.data?.content || '')
      }
    })
  }

  const sanitizeHTML = htmlString => {
    const sanitizedHTML = DOMPurify.sanitize(htmlString)
    return { __html: sanitizedHTML }
  }

  useEffect(() => {
    handleRequest(id)
  }, [id, lang])

  return (
    <PageWrapper>
      <FeatureBanner>
        {title && <div className={Styles.title}>{title}</div>}
        {date && <div className={Styles.date}>{date}</div>}
      </FeatureBanner>
      {!!htmlString && <div className={Styles.content} dangerouslySetInnerHTML={sanitizeHTML(htmlString)} />}
    </PageWrapper>
  )
}
