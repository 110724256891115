import Styles from './style.module.scss'
import useLang from '@/utils/hooks/use-lang'

const text = {
  en: 'Website under construction',
  es: 'Sitio web en construcción',
  cn: '网站建设中',
}

export default function Index() {
  const [lang] = useLang()

  return <div className={Styles.wrapper}>{text[lang]}</div>
}
