import Styles from './style.module.scss'
import ImageCover from '@/components/image-cover'
import { useNavigate } from 'react-router-dom'
import useLang from '@/utils/hooks/use-lang'

const btn_text = {
  en: 'View Details',
  es: 'Ver Detalles',
  cn: '查看详情',
}

export default function ProductList({ data }) {
  const navigate = useNavigate()
  const [lang] = useLang()
  if (!data?.length) return null

  return (
    <div className={Styles.list}>
      {data.map(d => (
        <div key={d.id} className={Styles.item} onClick={() => navigate(`/productDetail?id=${d.id}`)}>
          <ImageCover className={Styles.image_cover} src={`image/${d.image}`} />
          <div className={Styles.content}>
            <div className={Styles.title}>{d.title}</div>
            {/* <div className={Styles.model}>{d.model_name}</div> */}
            <div className={Styles.description}>{d.description}</div>
            <div className={Styles.detailButton}>{btn_text[lang]}</div>
          </div>
        </div>
      ))}
    </div>
  )
}
