import { useState, useEffect } from 'react'
import PageWrapper from '@/components/page-wrapper'
import FeatureBanner from '@/components/feature-banner'
import NewsTop from './top'
import List from './list'
import Styles from './style.module.scss'
import { get, post } from '@/utils/fetch'
import useLang from '@/utils/hooks/use-lang'

const title_text = {
  en: 'News',
  es: 'Noticias',
  cn: '新闻中心',
}

export default function Index() {
  const [lang] = useLang()
  const [topData, setTopData] = useState(null)

  const handleRequest = () => {
    get(`/api/news_top/${lang}`)().then(res => {
      if (res?.code === 0) {
        if (res?.data?.id) setTopData(res.data)
      }
    })
  }

  useEffect(() => {
    handleRequest()
  }, [lang])

  return (
    <PageWrapper>
      <FeatureBanner title={title_text[lang]} description='' />
      <div className={Styles.main}>
        <NewsTop {...topData} />
        <List />
      </div>
    </PageWrapper>
  )
}
