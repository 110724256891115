export const data = {
  en: [
    {
      cover: 'assets/video_cover.png',
      video_url: 'assets/introduce.mp4',
      title: 'Resilient & Innovative',
      description: 'renewable energy solution for Commercial & Industrial applications',
      list: [
        {
          icon: 'assets/icon_1_new.png',
          text: 'Systematic solution to address C&I needs',
        },
        {
          icon: 'assets/icon_2_new.png',
          text: 'Modularized design with easy-to-install scalability',
        },
        {
          icon: 'assets/icon_3_new.png',
          text: 'Design-in optimum Efficiency & Safety',
        },
        {
          icon: 'assets/icon_4_new.png',
          text: 'Continuously advancing the greener solutions',
        },
      ],
    },
  ],
  es: [
    {
      cover: 'assets/video_cover.png',
      video_url: 'assets/introduce.mp4',
      title: 'Resiliente e Innovador',
      description: 'Soluciones de Energía Renovable para aplicaciones Comerciales e Industriales',
      list: [
        {
          icon: 'assets/icon_1_new.png',
          text: 'Solución sistemática para abordar las necesidades de Comercios e Industrias (C&I).',
        },
        {
          icon: 'assets/icon_2_new.png',
          text: 'Diseño modular con escalabilidad fácil de instalar.',
        },
        {
          icon: 'assets/icon_3_new.png',
          text: 'Diseño para lograr la máxima eficiencia y seguridad.',
        },
        {
          icon: 'assets/icon_4_new.png',
          text: 'Avanzando continuamente en soluciones más ecológicas.',
        },
      ],
    },
  ],
  cn: [
    {
      cover: 'assets/video_cover.png',
      video_url: 'assets/introduce.mp4',
      title: '工商业智慧电力',
      description: '绿色能源系统解决方案',
      list: [
        {
          icon: 'assets/icon_1_new.png',
          text: '专注于工商业客户智慧电力控制系统方案',
        },
        {
          icon: 'assets/icon_2_new.png',
          text: '模块化系统选配以及升级方案',
        },
        {
          icon: 'assets/icon_3_new.png',
          text: '电力控制与存储极致安全高效',
        },
        {
          icon: 'assets/icon_4_new.png',
          text: '持续探索更优化的绿电成本方案',
        },
      ],
    },
  ],
}
