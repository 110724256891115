import PageWrapper from '@/components/page-wrapper'
import FeatureBanner from '@/components/feature-banner'
import Building from '@/components/building'
import { service_center } from '@/utils/data'
import useLang from '@/utils/hooks/use-lang'

export default function Detail() {
  const [lang] = useLang()
  const data = service_center[lang].find(d => `#${d.path}` === window.location.hash)
  return (
    <PageWrapper>
      <FeatureBanner title={data.title} description='' />
      <Building />
    </PageWrapper>
  )
}
