import PageWrapper from '@/components/page-wrapper'
import FeatureBanner from '@/components/feature-banner'
import Building from '@/components/building'
import useLang from '@/utils/hooks/use-lang'

const Data = {
  en: {
    title: 'Join Us',
    desc: 'Updating job requirements',
  },
  es: {
    title: 'Únete a Nosotros',
    desc: 'Actualización de requisitos laborales',
  },
  cn: {
    title: '加入我们',
    desc: '职位需求更新中',
  },
}

export default function Detail() {
  const [lang] = useLang()

  return (
    <PageWrapper>
      <FeatureBanner title={Data[lang].title} description={Data[lang].desc} />
      <Building />
    </PageWrapper>
  )
}
