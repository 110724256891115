import { useContext } from 'react'
import { GlobalContext } from '@/App'

export default function useLang() {
  const { globalProps, setGlobalProps } = useContext(GlobalContext)

  const setLang = v => {
    setGlobalProps({ lang: v })
  }

  const lang = globalProps?.lang || 'cn'

  return [lang, setLang]
}
