import { useNavigate } from 'react-router-dom'
import { Image } from 'react-bootstrap'
import Brand from '../brand'
import Styles from './style.module.scss'
import { data as _data } from './data.js'
import useLang from '@/utils/hooks/use-lang'

const Social = () => {
  return (
    <div className={Styles.social}>
      <Image src='assets/facebook.png' />
      <Image src='assets/twitter.png' />
      <Image src='assets/instagram.png' />
    </div>
  )
}

export default function Footer() {
  const navigate = useNavigate()
  const [lang] = useLang()
  const data = _data(lang)

  return (
    <div className={Styles.footer}>
      <div className={Styles.left}>
        <Brand onClick={() => navigate('/')} />
        <Social />
      </div>
      <div className={Styles.right}>
        {data.map(d => (
          <div className={Styles.column} key={d.title}>
            <div className={Styles.title} onClick={() => d?.path && navigate(d.path)}>
              {d.title}
            </div>
            {!!d?.children?.length && (
              <div className={Styles.children}>
                {d.children.map(i => (
                  <div className={Styles.item} key={i.title}>
                    {i.title}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
