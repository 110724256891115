import Styles from './style.module.scss'
import { useNavigate } from 'react-router-dom'
import { solution_data as _solution_data } from '@/utils/data'
import useLang from '@/utils/hooks/use-lang'

const Solution = {
  en: 'Solution',
  es: 'Solución',
  cn: '解决方案',
}

const Home = {
  en: 'Home',
  es: 'Página de inicio',
  cn: '首页',
}

export default function Index({ customData }) {
  const navigate = useNavigate()
  const [lang] = useLang()
  const solution_data = _solution_data[lang]
  const d = customData ?? solution_data.find(item => `#${item.path}` === window.location.hash)
  const { title, banner_url } = d

  return (
    <div className={Styles.container} style={{ backgroundImage: `url(${banner_url})` }}>
      <div className={Styles.content}>
        <div className={Styles.title}>{title}</div>
        {!customData && (
          <div className={Styles.breadcrumbs}>
            <span onClick={() => navigate('/')}>{Home[lang]}</span> &gt; {Solution[lang]} &gt; {title}
          </div>
        )}
        {customData && (
          <div className={Styles.breadcrumbs}>
            <span onClick={() => navigate('/')}>{Home[lang]}</span> &gt; {title}
          </div>
        )}
      </div>
    </div>
  )
}
