import { solution_data, service_center, about_us } from '@/utils/data'

export const menus = lang => {
  const solution = solution_data[lang]
  const service = service_center[lang]
  const about = about_us[lang]

  const data = {
    en: [
      {
        title: 'Home',
        path: '/',
      },
      {
        title: 'Solution',
        path: '',
        children: solution,
      },
      {
        title: 'Product',
        path: '/product',
      },
      {
        title: 'Resource Center',
        path: '',
        children: service,
      },
      {
        title: 'News',
        path: '/news',
      },
      {
        title: 'About Us',
        path: '',
        children: about,
      },
    ],
    es: [
      {
        title: 'Página de inicio',
        path: '/',
      },
      {
        title: 'Solución',
        path: '',
        children: solution,
      },
      {
        title: 'Producto',
        path: '/product',
      },
      {
        title: 'Centro de Recursos',
        path: '',
        children: service,
      },
      {
        title: 'Noticias',
        path: '/news',
      },
      {
        title: 'Sobre Nosotros',
        path: '',
        children: about,
      },
    ],
    cn: [
      {
        title: '首页',
        path: '/',
      },
      {
        title: '解决方案',
        path: '',
        children: solution,
      },
      {
        title: '产品',
        path: '/product',
      },
      {
        title: '服务中心',
        path: '',
        children: service,
      },
      {
        title: '新闻',
        path: '/news',
      },
      {
        title: '关于我们',
        path: '',
        children: about,
      },
    ],
  }

  return data[lang]
}
