import Styles from './style.module.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Navigation, Pagination } from 'swiper'
import useLang from '@/utils/hooks/use-lang'

const _data = {
  en: [
    {
      title: 'Professionalism',
      content:
        'Our team is equipped with extensive experience and technical know-how in the renewable energy industry, enabling us to provide clients with the best solutions needed.',
    },
    {
      title: 'One-Stop-Shop',
      content:
        'We offer a comprehensive range of services to meet all your energy needs, including energy design and consulting services, integration and installation of energy systems, as well as cloud-based data analytics services.',
    },
    {
      title: 'Customize Your Needs',
      content:
        'With our diverse offerings, we can provide you with a holistic and tailored energy solution that meets your specific requirements',
    },
    {
      title: 'Reliable Supply Chain',
      content:
        'We have established strong relationships with reliable suppliers and partners, ensuring the reliability and quality of the energy storage equipment and services we provide.',
    },
    {
      title: 'Sustainable Solutions',
      content:
        'We are committed to providing sustainable energy solutions that help our clients achieve their energy efficiency and carbon footprint reduction goals. Our solutions align with the growing sustainable development strategies of numerous businesses.',
    },
  ],
  es: [
    {
      title: 'Profesionalismo',
      content:
        'Nuestro equipo está equipado con una amplia experiencia y conocimientos técnicos en la industria de la energía renovable, lo que nos permite proporcionar a los clientes las mejores soluciones necesarias.',
    },
    {
      title: 'Todo en Uno',
      content:
        'Ofrecemos una amplia gama de servicios integrales para satisfacer todas sus necesidades energéticas, que incluyen servicios de diseño y consultoría energética, integración e instalación de sistemas energéticos, así como servicios de análisis de datos en la nube.',
    },
    {
      title: 'Personaliza tus necesidades',
      content:
        'Con nuestras diversas ofertas, podemos proporcionarle una solución energética integral y personalizada que satisfaga sus requisitos específicos.',
    },
    {
      title: 'Cadena de suministro confiable',
      content:
        'Hemos establecido relaciones sólidas con proveedores y socios confiables, garantizando la confiabilidad y calidad del equipo y servicios de almacenamiento de energía que ofrecemos.',
    },
    {
      title: 'Soluciones Sostenibles',
      content:
        'Estamos comprometidos a proporcionar soluciones de energía sostenible que ayuden a nuestros clientes a alcanzar sus objetivos de eficiencia energética y reducción de la huella de carbono. Nuestras soluciones se alinean con las estrategias de desarrollo sostenible en crecimiento de numerosas empresas.',
    },
  ],
  cn: [
    {
      title: '专业知识',
      content: '拥有丰富的储能行业经验和技术知识，能够提供客户所需的最佳解决方案',
    },
    {
      title: '全面的能源服务',
      content:
        '不仅提供储能设备的集成和安装，还提供能源咨询和设计、云平台的储能服务、储能设备租赁等多种服务，能够为客户提供全面的能源解决方案。',
    },
    {
      title: '客户定制化',
      content: '提供定制化的储能系统和服务，能够根据客户的特 定需求和预算来设计和实现最佳解决方案。',
    },
    {
      title: '可靠的供应链',
      content: '我们与可靠的供应商和合作伙伴建立了良好的关系，能够确保我们提供的储能设备和服务的可靠性和质量。',
    },
    {
      title: '可持续的解决方案',
      content:
        '我们致力于提供可持续的能源解决方案，帮助客户实现能源效率和减少碳足迹的目标，符合越来越多企业的可持续发展战略。',
    },
  ],
}

const Columns = 3

export default function MySwiper() {
  const [lang] = useLang()
  const data = _data[lang]

  return (
    <Swiper
      className={Styles.mySwiper}
      autoHeight={true}
      spaceBetween={1}
      navigation={true}
      pagination={{
        clickable: true,
        type: 'progressbar',
      }}
      modules={[Navigation, Pagination]}
      slidesPerView={Columns}
      onSlideChange={() => console.log('slide change')}
      onSwiper={swiper => console.log(swiper)}
    >
      {data.map(d => (
        <SwiperSlide key={d.title} className={Styles.swiper_item}>
          <div className={Styles.swiper_item_title}>{d.title}</div>
          <div className={Styles.swiper_item_content}>{d.content}</div>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}
