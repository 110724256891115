import Styles from './style.module.scss'

export default function Index({ title, description, children, backgroundImage = 'feature_banner' }) {
  return (
    <div className={Styles.feature_banner} style={{ backgroundImage: `url(assets/${backgroundImage}.png)` }}>
      {title && <div className={Styles.title}>{title}</div>}
      {description && <div className={Styles.desc}>{description}</div>}
      {children}
    </div>
  )
}
