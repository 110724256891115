import { useState } from 'react'
import ImageCover from '../image-cover'
import Styles from './style.module.scss'
import { useNavigate } from 'react-router-dom'
import useLang from '@/utils/hooks/use-lang'

const contact = {
  en: 'Product Consultation',
  es: 'Consulta sobre el producto',
  cn: '产品咨询',
}

export default function Contact() {
  const [lang] = useLang()
  const navigate = useNavigate()
  const [hover, setHover] = useState(false)

  return (
    <div
      className={Styles.contact}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => navigate('/contact')}
    >
      <ImageCover className={Styles.image_cover} src={`assets/chat${hover ? '_white' : ''}.png`} />
      <span>{contact[lang]}</span>
    </div>
  )
}
