import React, { useRef, useState, useEffect, useCallback } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import { Pagination } from 'swiper'
import ImageCover from '@/components/image-cover'
import useLang from '@/utils/hooks/use-lang'
import { useNavigate } from 'react-router-dom'
import Styles from './style.module.scss'
import { get, post } from '@/utils/fetch'

const btnText = {
  en: 'View Details',
  es: 'Ver Detalles',
  cn: '查看详情',
}

const Item = props => {
  const navigate = useNavigate()
  const [lang] = useLang()
  const { id, image, date, title } = props

  return (
    <div className={Styles.swiper_item}>
      <ImageCover className={Styles.image_cover} src={`image/${image}`} />
      <div className={Styles.main}>
        <div className={Styles.date}>{date}</div>
        <div className={Styles.title}>{title}</div>
        <div className={Styles.btn} onClick={() => navigate(`/news-detail?id=${id}`)}>
          {btnText[lang]}
        </div>
      </div>
    </div>
  )
}

export default function Index() {
  const navigate = useNavigate()
  const [top, setTop] = useState({})
  const [list, setList] = useState([])
  const [lang] = useLang()

  const handleRequest = () => {
    get(`/api/news/${lang}?page=1&pagesize=100`)().then(res => {
      if (res?.code === 0) {
        const l = res?.data || []
        setList(l)
      }
    })
  }

  const handleTopRequest = () => {
    get(`/api/news_top/${lang}`)().then(res => {
      if (res?.code === 0) {
        if (res?.data?.id) setTop(res.data)
      }
    })
  }

  useEffect(() => {
    handleTopRequest()
    handleRequest()
  }, [lang])

  return (
    <div className={Styles.container}>
      <Swiper
        slidesPerView={'auto'}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className={Styles.mySwiper}
      >
        {[top, ...list].map((d, index) => (
          <SwiperSlide key={index}>
            <Item {...d} />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className={Styles.more} onClick={() => navigate(`/news`)}>
        Learn More →{/* <ImageCover className={Styles.arrow} src='assets/arrow_right.png' /> */}
      </div>
    </div>
  )
}
