import { useNavigate } from 'react-router-dom'
import Styles from './style.module.scss'
import useLang from '@/utils/hooks/use-lang'

const data = {
  en: {
    title: 'Embrace the Energy-Efficient Lifestyle!',
    subtitle: 'Contact us to use green energy to achieve intelligent electricity',
    btnText: 'Contact Us',
  },
  es: {
    title: 'Adopta el Estilo de Vida Energéticamente Eficiente',
    subtitle: 'Contáctanos para utilizar energía verde y lograr electricidad inteligente.',
    btnText: 'Contáctenos',
  },
  cn: {
    title: '开启节能生活',
    subtitle: '联系我们使用绿色能源实现智慧电力',
    btnText: '联系我们',
  },
}

export default function Index() {
  const navigate = useNavigate()
  const [lang] = useLang()
  const dataText = data[lang]

  return (
    <div
      className={Styles.wrapper}
      style={{ backgroundImage: `url(assets/${lang}/solution-footer-banner.png)` }}
      onClick={() => navigate('/contact')}
    >
      {/* <div className={Styles.title}>{dataText.title}</div>
      <div className={Styles.subtitle}>{dataText.subtitle}</div>
      <div className={Styles.button} onClick={() => navigate('/contact')}>
        {dataText.btnText}
      </div> */}
    </div>
  )
}
