import { Image } from 'react-bootstrap'
import Styles from './style.module.scss'
import ClassName from 'clsx'

export default function ImageCover(props) {
  const { src, className, style } = props

  if (!src) return null

  return (
    <div className={ClassName(Styles.image_wrapper, className)} style={style}>
      <Image src={src} />
    </div>
  )
}
