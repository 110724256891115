export const data = {
  en: {
    title: 'Clouding Service',
    content:
      'We offer cloud-based services that include real-time monitoring, data analysis, and remote control. These services help users manage and optimize their energy management systems, improve overall efficiency and reliability, and reduce energy costs.',
    list: [
      {
        icon: 'cloud_icon_1',
        title: 'Real-Time Monitoring',
        content:
          'The cloud service enables real-time monitoring and control of energy facilities. Allowing administrators or authorized users to access facility data and operational interfaces through the internet. Whenever needed, users can remotely take control to make adjustments, ensuring efficient operation.',
      },
      {
        icon: 'cloud_icon_2',
        title: 'Collaboration and Management Remotely',
        content:
          'Multiple users and administrators can collaborate and share data remotely. This facilitates cross-department and cross-regional collaboration, improving efficiency and accuracy in management. Additionally, it provides hierarchical access permissions to ensure data and operational security and privacy.',
      },
      {
        icon: 'cloud_icon_3',
        title: 'Intelligent data analysis and optimization',
        content:
          'Through cloud-based data analysis, administrators can gain insights into the operational status, energy consumption, and performance of energy facilities. This enables administrators to identify and address potential issues, optimize energy usage, and improve energy efficiency and conservation.',
      },
      {
        icon: 'cloud_icon_4',
        title: 'Fault Detection and Maintenance',
        content:
          'The system is capable of automatically detecting faults of connected devices and promptly issuing alerts to administrators. This enables administrators to respond quickly and carry out necessary repairs. Additionally, the system can provide maintenance guidelines and recommendations to better assist facility management.',
      },
    ],
  },
  es: {
    title: 'Servicio en la Nube',
    content:
      'Ofrecemos servicios basados en la nube que incluyen monitoreo en tiempo real, análisis de datos y control remoto. Estos servicios ayudan a los usuarios a gestionar y optimizar sus sistemas de gestión de energía, mejorar la eficiencia y confiabilidad en general, y reducir los costos energéticos.',
    list: [
      {
        icon: 'cloud_icon_1',
        title: 'Monitoreo en Tiempo Real',
        content:
          'El servicio en la nube permite el monitoreo y control en tiempo real de las instalaciones energéticas, lo que permite a los administradores o usuarios autorizados acceder a los datos de las instalaciones y a las interfaces operativas a través de internet. Cuando sea necesario, los usuarios pueden tomar el control de forma remota para realizar ajustes, asegurando un funcionamiento eficiente.',
      },
      {
        icon: 'cloud_icon_2',
        title: 'Colaboración y Gestión Remota',
        content:
          'Varios usuarios y administradores pueden colaborar y compartir datos de forma remota. Esto facilita la colaboración entre departamentos y regiones, mejorando la eficiencia y la precisión en la gestión. Además, proporciona permisos de acceso jerárquicos para garantizar la seguridad y privacidad de los datos y las operaciones.',
      },
      {
        icon: 'cloud_icon_3',
        title: 'Análisis inteligente de datos y optimización',
        content:
          'A través del análisis de datos basado en la nube, los administradores pueden obtener información sobre el estado operativo, el consumo de energía y el rendimiento de las instalaciones energéticas. Esto les permite identificar y abordar posibles problemas, optimizar el uso de energía y mejorar la eficiencia y conservación de la energía.',
      },
      {
        icon: 'cloud_icon_4',
        title: 'Detección de Fallas y Mantenimiento',
        content:
          'El sistema es capaz de detectar automáticamente fallas en los dispositivos conectados y emitir alertas de manera oportuna a los administradores. Esto permite a los administradores responder rápidamente y llevar a cabo las reparaciones necesarias. Además, el sistema puede proporcionar pautas y recomendaciones de mantenimiento para ayudar mejor en la gestión de las instalaciones.',
      },
    ],
  },
  cn: {
    title: '云平台应用优势',
    content:
      '我们提供基于云平台的储能系统服务，包括实时监测、数据分析和远程控制等服务。这些服务可以帮助用户管理和优化其储能系统，提高电力系统的效率和可靠性，降低能源成本。',
    list: [
      {
        icon: 'cloud_icon_1',
        title: '即时监控',
        content:
          '云端服务平台可以实时监控和控制绿能设施的运行状态。它提供了远程监控的能力，使管理人员可以随时随地透过互联网访问设施的数据和操作界面。这使得管理人员能够及时检测异常情况，做出相应的调整和控制，确保绿能设施的高效运行。',
      },
      {
        icon: 'cloud_icon_2',
        title: '远程协作和管理',
        content:
          '多个用户和管理人员能够远程协作和共享数据。这促进了跨部门和跨地域的协作，提高了管理的效率和准确性。同时，它还提供了分级的访问权限，确保数据和操作的安全性和隐私性。',
      },
      {
        icon: 'cloud_icon_3',
        title: '智慧化数据分析和优化',
        content:
          '云端服务平台收集和存储了大量的数据，并提供强大的数据分析工具。透过数据分析，管理人员可以深入了解绿能设施的运行状态、能源消耗情况和性能表现。这使得管理人员能够识别和解决潜在问题，优化能源使用，提高能源效率和节能效果。',
      },
      {
        icon: 'cloud_icon_4',
        title: '故障检测和维护',
        content:
          '云端服务平台可以自动检测设施的故障或异常状况，并及时向管理人员发出警报。这提高了故障检测的及时性和精确性，使管理人员能够快速响应并进行维修。此外，平台还可以提供维护指南和建议，帮助管理人员有效管理和维护绿能设施。',
      },
    ],
  },
}
