export function toUrlParams(obj) {
  return Object.keys(obj)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`)
    .join('&')
}

const Prefix = process.env.NODE_ENV === 'production' ? '' : ''

const myFetch = (url, options) => {
  const defaultOptions = {
    credentials: 'include',
    mode: 'cors',
  }
  const newOptions = { ...defaultOptions, ...options }
  if (newOptions.method === 'POST' || newOptions.method === 'PUT' || newOptions.method === 'DELETE') {
    newOptions.body = JSON.stringify(newOptions.body)
  }
  return new Promise((resolve, reject) => {
    fetch(`${Prefix}${url}`, newOptions)
      .then(response => {
        if (response.status === 200) {
          return response.json()
        }
        return response.json()
      })
      .then(data => {
        resolve(data)
      })
      .catch(err => {
        throw new Error(err.toString())
      })
  })
}

export function post(url, options) {
  return params =>
    myFetch(url, {
      method: 'POST',
      headers: { ...options, 'Content-Type': 'application/json' },
      body: { ...params },
    })
}

export function get(url, options) {
  return param => {
    const [targetUrl] = url.split('?')
    url = param ? `${targetUrl}?${toUrlParams(param)}` : targetUrl
    return myFetch(url, { method: 'GET', ...options })
  }
}

export default myFetch
