import PageWrapper from '@/components/page-wrapper'
import FeatureBanner from '@/components/feature-banner'
import Styles from './style.module.scss'
import useLang from '@/utils/hooks/use-lang'

const _data = {
  en: {
    title: 'Contact Us',
    description: 'Consultation invitation or purchase please contact us',
    list: [
      {
        title: 'Address',
        // content: ['Region: ,OR', 'Detailed address: 13970 SW 72nd Ave, Portland', 'Postal Code: 97223'],
        content: ['780 MONTAGUE EXPRESSWAY,', 'UNIT1101, SAN JOSE, CA 95131'],
      },
      {
        title: 'Contact information',
        content: ['1: 541-9136289', '2: 408-476-9312'],
      },
      {
        title: 'Email',
        content: ['Email: info@carusg.com'],
      },
    ],
  },
  es: {
    title: 'Contáctanos',
    description: 'Para consultas o compras, por favor contáctenos.',
    list: [
      {
        title: 'Dirección',
        // content: ['Region: ,OR', 'Detailed address: 13970 SW 72nd Ave, Portland', 'Postal Code: 97223'],
        content: ['780 MONTAGUE EXPRESSWAY,', 'UNIT1101, SAN JOSE, CA 95131'],
      },
      {
        title: 'Información de contacto',
        content: ['1: 541-9136289', '2: 408-476-9312'],
      },
      {
        title: 'Correo electrónico',
        content: ['Correo electrónico: info@carusg.com'],
      },
    ],
  },
  cn: {
    title: '联系我们',
    description: '咨询邀约或购买请联系我们',
    list: [
      {
        title: '地址',
        // content: ['地区：,OR', '详细地址：13970 SW 72nd Ave, Portland', '邮编：97223'],
        content: ['780 MONTAGUE EXPRESSWAY,', 'UNIT1101, SAN JOSE, CA 95131'],
      },
      {
        title: '联系方式',
        content: ['联系方式：541-9136289', '联系方式：408-476-9312'],
      },
      {
        title: '邮箱',
        content: ['邮箱：info@carusg.com'],
      },
    ],
  },
}

export default function Detail() {
  const [lang] = useLang()
  const data = _data[lang]

  return (
    <PageWrapper>
      <FeatureBanner title={data.title} description={data.description} />
      <div className={Styles.main}>
        <div className={Styles.content}>
          {data?.list.map(d => (
            <div className={Styles.item} key={d.title}>
              <div className={Styles.title}>{d.title}</div>
              <div className={Styles.details}>
                {d.content?.map(c => (
                  <span key={c}>{c}</span>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </PageWrapper>
  )
}
