export const data = {
  en: {
    title: '',
    content:
      'Carus Group is an innovative company specializing in the design, development, and cloud services of renewable energy systems for commercial and industrial applications. Our goal is to provide our clients with reliable, efficient, and cost-effective energy solutions. We aim to help them reduce energy costs, improve energy efficiency, and promote sustainable business practices.',
    p1: '',
    p2: 'Solutions we offer ..',
    tag: 'Carus Culture',
    values:
      'Value-Add; Trusted Partner; Customer-Centric; Service Excellence; Responsive Solutions; Long-Term Relations;',
    list: [
      {
        icon: 'cloud_icon_1',
        title: 'Vision',
        content:
          'We aim to be your premium solution provider for renewable energy applications, empowering our clients to establish greener, more economical, and smarter power systems.',
      },
      {
        icon: 'cloud_icon_2',
        title: 'Mission',
        content:
          'Offering the very best renewable energy solutions covering smart power management, green energy design, data analytics, visualized control systems, and more',
      },
      // {
      //   icon: 'cloud_icon_3',
      //   title: 'Strategic Positioning',
      //   content:
      //     'Our goal is to be a world-class integrated solution provider for renewable energy systems. We provide comprehensive integrated solutions for power control equipment, battery storage systems, and energy storage systems in various applications for commercial and industrial sectors.',
      // },
    ],
  },
  es: {
    title: '',
    content:
      'Carus Group es una empresa innovadora especializada en el diseño, desarrollo y servicios en la nube de sistemas de energía renovable para aplicaciones comerciales e industriales. Nuestro objetivo es proporcionar a nuestros clientes soluciones energéticas confiables, eficientes y rentables. Nos esforzamos por ayudarles a reducir los costos energéticos, mejorar la eficiencia energética y promover prácticas comerciales sostenibles.',
    p1: '',
    p2: 'Soluciones que ofrecemos…',
    tag: 'Cultura de Carus',
    values:
      'Valor Agregado; Socio Confiable; Centrado en el Cliente; Excelencia en el Servicio; Soluciones Responsivas; Relaciones a Largo Plazo;',
    list: [
      {
        icon: 'cloud_icon_1',
        title: 'Visión',
        content:
          'Nuestro objetivo es ser su proveedor premium de soluciones para aplicaciones de energía renovable, capacitando a nuestros clientes para establecer sistemas de energía más verdes, económicos y inteligentes.',
      },
      {
        icon: 'cloud_icon_2',
        title: 'Misión',
        content:
          'Ofrecemos las mejores soluciones de energía renovable que cubren la gestión inteligente de la energía, el diseño de energía verde, análisis de datos, sistemas de control visualizados y más.',
      },
    ],
  },
  cn: {
    title: '',
    content:
      '公司是一家专注于工商业储能系统的设计、开发、云服务、和融资租赁的创新型企业。我们的目标是为工商 业客户提供可靠、高效、经济的能源解决方案，帮助他们降低能源成本，提高能源效率，同时推动可持续能源的发展。',
    p1: '业务结构',
    p2: '我们主要的产品和服务',
    tag: '企业文化',
    values: '价值导向 信任共赢 客户导向 专精服务 责任导向 长期主义',
    list: [
      {
        title: '愿景',
        content: '致力于成为全球数字能源技术与解决方案的优秀提供者，助力客户建立更 绿色、更经济、更智慧的电力系统。',
      },
      {
        title: '使命',
        content: '智慧电力、绿色能源，包括:数据互联，可视化电力系统，360度监控，云平台',
      },
      // {
      //   title: '战略定位',
      //   content:
      //     '致力于成为全球一流的分布式储能系统集成商和解决方案提供商，为客户提供工商业能源、 网络能源等应用领域的电力控制设备、储能系统设备及电力系统综合一体化解决方案。',
      // },
    ],
  },
}
