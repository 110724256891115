import { useNavigate } from 'react-router-dom'
import ImageCover from '@/components/image-cover'
import Styles from './style.module.scss'
import useLang from '@/utils/hooks/use-lang'

export default function Index(props) {
  const navigate = useNavigate()
  const [lang] = useLang()
  const { id, image, date, title } = props
  const btn_text = {
    en: 'View Details',
    es: 'Ver Detalles',
    cn: '查看详情',
  }

  if (!id) return null

  return (
    <div className={Styles.top_news}>
      {image && <ImageCover className={Styles.image_cover} src={`image/${image}`} />}
      <div className={Styles.top_news_main}>
        {date && <div className={Styles.date}>{date}</div>}
        {title && <div className={Styles.title}>{title}</div>}
        <div className={Styles.btn} onClick={() => navigate(`/news-detail?id=${id}`)}>
          {btn_text[lang]}
        </div>
      </div>
    </div>
  )
}
