import PageWrapper from '@/components/page-wrapper'
import Icon from '@/components/icon'
import SolutionBanner from '@/components/solution-banner'
import SolutionFooterBanner from '@/components/solution-footer-banner'
import Styles from './style.module.scss'
import { data } from './data.js'
import useLang from '@/utils/hooks/use-lang'

export default function Index(props) {
  const [lang] = useLang()
  const dataText = data[lang]

  return (
    <PageWrapper soul>
      <SolutionBanner />
      <div className={Styles.article}>
        <div className={Styles.title}>{dataText.title}</div>
        <div className={Styles.content}>{dataText.content}</div>
      </div>
      <div className={Styles.list} style={{ backgroundImage: `url(assets/financial_back.png)` }}>
        {dataText.list.map((item, index) => {
          return (
            <div key={index} className={Styles.item}>
              <div className={Styles.item_title}>
                <Icon className={Styles.icon} src={item.icon} />
                <di className={Styles.title}>{item.title}</di>
              </div>
              <div className={Styles.item_content}>{item.content}</div>
            </div>
          )
        })}
      </div>
      <SolutionFooterBanner />
    </PageWrapper>
  )
}
