export const title = {
  en: 'Let us help you with ..',
  es: 'Permítanos ayudarle con...',
  cn: '价值与服务',
}

export const slogan_title = {
  en: 'By leveraging advanced technology and smart monitoring',
  es: 'By leveraging advanced technology and smart monitoring',
  cn: '利用先进技术和智能监控',
}

export const slogan_desc = {
  en: 'we help you maximize energy efficiency, minimize waste, and ultimately optimize your electricity costs',
  es: 'te ayudamos a maximizar la eficiencia energética, minimizar el desperdicio y, en última instancia, optimizar tus costos eléctricos',
  cn: '我们帮助您最大限度地提高能源效率，最大限度地减少浪费，并最终优化您的电力成本',
}

export const contact_us = {
  en: 'Contact Us',
  es: 'Contáctenos',
  cn: '联系我们',
}

export const data = {
  en: [
    {
      icon: 'assets/energy.png',
      icon_white: 'assets/energy_white.png',
      image: 'assets/scene_energy_1.png',
      title: 'Harness & Optimize Energy Cost',
      tag: 'Harness & Optimize Energy Cost',
      list: [
        'Discover the power of renewable energy and unlock cost optimization for your business.',
        'With our cutting-edge renewable energy system, you can harness the abundant resources of wind, solar, and more to generate clean, sustainable power.',
        'Our system enables you to tap into long-term savings by reducing dependency on traditional energy sources.',
        'By leveraging advanced technology and smart monitoring, we help you maximize energy efficiency, minimize waste, and ultimately optimize your electricity costs.',
      ],
    },
    {
      icon: 'assets/efficiency.png',
      icon_white: 'assets/efficiency_white.png',
      image: 'assets/scene_efficiency_1.png',
      title: 'Stability, Efficiency & Peace-of-mind',
      tag: 'Stability, Efficiency & Peace-of-mind',
      list: [
        'Experience unparalleled stability and efficiency with our system tailored for commercial & industrial owners.',
        'Our solution addresses the critical considerations of stability and efficiency, empowering your operations to thrive.',
        'Our system offers a resilient power supply that safeguards against unexpected power grid outages and prevents costly interruptions and downtime.',
        "Embrace advance technologies, enhance productivity, and secure your business's future with our reliable and efficient renewable energy system.",
      ],
    },
    {
      icon: 'assets/sustainable.png',
      icon_white: 'assets/sustainable_white.png',
      image: 'assets/scene_sustainable_1.png',
      title: 'Sustainability Advancement',
      tag: 'Sustainability Advancement',
      list: [
        'Elevate your business with a more sustainable transformation by adopting our system for your commercial or industrial operations.',
        'Not only does it enhance your corporate ESG (Environmental, Social, and Governance) profile, but it also drives significant reductions in carbon emissions, aligning with sustainability goals.',
        'Additionally, our scalable system supports the integration of EV charging stations, enabling the electrification of transportation and catering to the growing demand for sustainable mobility.',
      ],
    },
  ],
  es: [
    {
      icon: 'assets/energy.png',
      icon_white: 'assets/energy_white.png',
      image: 'assets/scene_energy.png',
      title: 'Aprovechar y optimizar el costo de la energía.',
      tag: 'Aprovechar y optimizar el costo de la energía.',
      list: [
        'Descubre el poder de la energía renovable y desbloquea la optimización de costos para tu negocio.',
        'Con nuestro sistema de energía renovable de vanguardia, puedes aprovechar los abundantes recursos del viento, solar y más para generar energía limpia y sostenible.',
        'Nuestro sistema te permite aprovechar los ahorros a largo plazo al reducir la dependencia de fuentes de energía tradicionales.',
        'Al aprovechar la tecnología avanzada y el monitoreo inteligente, te ayudamos a maximizar la eficiencia energética, minimizar el desperdicio y, en última instancia, optimizar tus costos eléctricos.',
      ],
    },
    {
      icon: 'assets/efficiency.png',
      icon_white: 'assets/efficiency_white.png',
      image: 'assets/scene_efficiency.png',
      title: 'Estabilidad, eficiencia y tranquilidad.',
      tag: 'Estabilidad, eficiencia y tranquilidad.',
      list: [
        'Experimenta una estabilidad y eficiencia sin igual con nuestro sistema diseñado para propietarios comerciales e industriales.',
        'Nuestra solución aborda las consideraciones críticas de estabilidad y eficiencia, potenciando tus operaciones para prosperar.',
        'Nuestro sistema ofrece un suministro de energía resistente que protege contra cortes inesperados en la red eléctrica y previene interrupciones costosas y tiempo de inactividad.',
        "Aprovecha las tecnologías avanzadas, mejora la productividad y asegura el futuro de tu negocio con nuestro sistema de energía renovable confiable y eficiente.",
      ],
    },
    {
      icon: 'assets/sustainable.png',
      icon_white: 'assets/sustainable_white.png',
      image: 'assets/scene_sustainable.png',
      title: 'Avance en Sostenibilidad',
      tag: 'Avance en Sostenibilidad',
      list: [
        'Eleva tu negocio con una transformación más sostenible al adoptar nuestro sistema para tus operaciones comerciales o industriales.',
        'No solo mejora tu perfil corporativo de ESG (ambiental, social y de gobierno), sino que también impulsa reducciones significativas en las emisiones de carbono, alineándose con objetivos de sostenibilidad.',
        'Además, nuestro sistema escalable apoya la integración de estaciones de carga de vehículos eléctricos, permitiendo la electrificación del transporte y satisfaciendo la creciente demanda de movilidad sostenible.',
      ],
    },
  ],
  cn: [
    {
      icon: 'assets/energy.png',
      icon_white: 'assets/energy_white.png',
      image: 'assets/scene_energy_1.png',
      title: '优化用电成本',
      tag: '优化用电成本',
      list: [
        '储能系统拥有调峰和储能功能',
        '用电高峰期节约用电成本',
        '用电低谷时供应额外电能',
        ' Up to 30% More Energy by Optimizing Each Module Performance',
        '2 Strings per MPPT, More Energy Yields',
        'PID Recovery Built-in to Secure Better Module Performance',
      ],
    },
    {
      icon: 'assets/efficiency.png',
      icon_white: 'assets/efficiency_white.png',
      image: 'assets/scene_efficiency_1.png',
      title: '稳定与效率',
      tag: '稳定与效率',
      list: [
        '提高电网稳定性：增加企业对电网的稳定性和可靠性，降低停电风险',
        '提高能源效率：通过电能储存和利用的方式降低企业的用电成本',
        '提供备用电源：保障生产和业务的连续性，在电力供应中断或故障时提供紧急电力支持',
        'AI Powered Active Arcing Protection',
        'C&I Tailored Enhanced Active Arcing Protection',
        '0V Module-level Voltage Shutdown, Touch Safe',
      ],
    },
    {
      icon: 'assets/sustainable.png',
      icon_white: 'assets/sustainable_white.png',
      image: 'assets/scene_sustainable_1.png',
      title: '可持续发展',
      tag: '可持续发展',
      list: [
        '企业ESG目标管理：使用最清洁、最可持续的化学原料。我们的电池产生的环境污染更少。',
        '电动汽车充电站：中小型企业需要提供可靠的充电设施来满足员工和客户的需求。',
        '可持续能源解决方案：储能系统可以帮助他们实现减少碳排放和能源消耗等目标。',
      ],
    },
  ],
}
