import React from 'react'
import { Image } from 'react-bootstrap'
import ImageCover from '@/components/image-cover'
import Styles from './style.module.scss'
import ClassName from 'clsx'
import useLang from '@/utils/hooks/use-lang'
import { data as _data } from './data'

export default function Index(props) {
  const [lang] = useLang()
  const data = _data[lang] || null

  return (
    <div className={Styles.container}>
      {!!data?.length &&
        data.map((d, index) => (
          <div key={index} className={Styles.column}>
            <div className={Styles.content}>
              <div className={Styles.title}>{d.title}</div>
              <div className={Styles.desc}>{d.description}</div>
              <div className={Styles.list}>
                {d.list.map(i => (
                  <div key={i.text} className={Styles.cell}>
                    <Image src={i.icon} />
                    <span>{i.text}</span>
                  </div>
                ))}
              </div>
            </div>
            {/* <ImageCover className={Styles.image_cover} src={d.cover} /> */}
            <video style={{ width: '100%' }} muted controls src={d.video_url} poster={d.cover} />
          </div>
        ))}
    </div>
  )
}
