import PageWrapper from '@/components/page-wrapper'
import SolutionBanner from '@/components/solution-banner'
import SolutionFooterBanner from '@/components/solution-footer-banner'
import Styles from './style.module.scss'
import { data } from './data.js'
import useLang from '@/utils/hooks/use-lang'

export default function Index() {
  const [lang] = useLang()
  const dataText = data[lang]

  return (
    <PageWrapper soul>
      <SolutionBanner />
      <div className={Styles.article}>
        <div className={Styles.title}>{dataText.title}</div>
        <div className={Styles.content}>{dataText.content}</div>
      </div>
      <div className={Styles.banner} style={{ backgroundImage: `url(assets/design_back.png)` }}>
        <div className={Styles.list}>
          {dataText.list.map((item, index) => {
            return (
              <div key={index} className={Styles.item}>
                <div className={Styles.item_title}>
                  {/* <Icon className={Styles.icon} src={item.icon} /> */}
                  <di className={Styles.title}>{item.title}</di>
                </div>
                <div className={Styles.item_content}>{item.content}</div>
              </div>
            )
          })}
        </div>
      </div>
      <SolutionFooterBanner />
    </PageWrapper>
  )
}
