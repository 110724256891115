import { Image } from 'react-bootstrap'
import Styles from './style.module.scss'

export default function Brand({ white, onClick }) {
  return (
    <div className={Styles.brand} onClick={onClick}>
      {!white && (
        <>
          <Image className={Styles.logo} src={'assets/logo.png'} />
          <Image className={Styles.name} src={'assets/brand.png'} />
        </>
      )}

      {white && (
        <>
          <Image className={Styles.logo} src={'assets/logo_white.png'} />
          <Image className={Styles.name} src={'assets/brand_white.png'} />
        </>
      )}
    </div>
  )
}
