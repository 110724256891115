import PageWrapper from '@/components/page-wrapper'
import SolutionBanner from '@/components/solution-banner'
import Advantage from '@/components/advantage-solution-dark'
import SolutionFooterBanner from '@/components/solution-footer-banner'
import ImageCover from '@/components/image-cover'
import Styles from './style.module.scss'
import useLang from '@/utils/hooks/use-lang'
import { data as _data, titleText as _titleText } from './data'

const slogan = {
  en: 'We offer a series of flexible and scalable solutions to meet your C&I needs',
  es: 'Ofrecemos una serie de soluciones flexibles y escalables para satisfacer tus necesidades comerciales e industriales (C&I).',
  cn: '我们提供完善的工商业绿能解決方案',
}

export default function Index() {
  const [lang] = useLang()
  const data = _data[lang]
  const titleText = _titleText[lang]

  return (
    <PageWrapper soul>
      <SolutionBanner hashName={window.location.hash} />
      <div className={Styles.feature}>
        <div className={Styles.title}>{slogan[lang]}</div>
        <ImageCover src='assets/flow.png' className={Styles.image_cover} />
      </div>
      <Advantage title={titleText} data={data} />
      <SolutionFooterBanner />
    </PageWrapper>
  )
}
