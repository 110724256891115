import { useNavigate } from 'react-router-dom'
import PageWrapper from '@/components/page-wrapper'
import SolutionBanner from '@/components/solution-banner'
import { about_us as _about_us } from '@/utils/data'
import Styles from './style.module.scss'
import { data as _data } from './data.js'
import Swiper from '@/components/swiper'
import { solution_data as _solution_data } from '@/utils/data'
import useLang from '@/utils/hooks/use-lang'

export default function Index() {
  const navigate = useNavigate()
  const [lang] = useLang()
  const about_us = _about_us[lang]
  const data = about_us.find(item => `#${item.path}` === window.location.hash)
  const solution_data = _solution_data[lang]
  const dataText = _data[lang]

  return (
    <PageWrapper soul>
      <SolutionBanner customData={data} />
      <div className={Styles.article}>
        <div className={Styles.title}>{dataText.title}</div>
        <div className={Styles.content}>{dataText.content}</div>
      </div>
      <div className={Styles.swiper}>
        <Swiper />
      </div>
      <div className={Styles.solution} style={{ backgroundImage: `url(assets/about_1_other.png)` }}>
        <div className={Styles.title}>
          {dataText.p1 && <div className={Styles.p1}>{dataText.p1}</div>}
          {dataText.p2 && <div className={Styles.p2}>{dataText.p2}</div>}
        </div>
        <div className={Styles.row}>
          {solution_data.map(d => (
            <div key={d.title} className={Styles.item} onClick={() => navigate(d.path)}>
              {d.title}
            </div>
          ))}
        </div>
      </div>
      <div className={Styles.banner} style={{ backgroundImage: `url(${data.image_url})` }}>
        <div className={Styles.banner_title}>{dataText.tag}</div>
        <div className={Styles.banner_subtitle}>{dataText.values}</div>
        <div className={Styles.list}>
          {dataText.list.map((item, index) => {
            return (
              <div key={index} className={Styles.item}>
                <div className={Styles.item_title}>
                  <di className={Styles.title}>{item.title}</di>
                </div>
                <div className={Styles.item_content}>{item.content}</div>
              </div>
            )
          })}
        </div>
      </div>
    </PageWrapper>
  )
}
