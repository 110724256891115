export const data = {
  en: {
    title:
      'Our goal is to provide professional guidance and support in the design and implementation process of energy systems, ensuring the success and maximizing the benefits of projects.',
    content:
      'Based on specific requirements, you can choose the corresponding service items and collaborate with our team of relevant professionals',
    list: [
      {
        icon: 'cloud_icon_1',
        title: 'Design',
        content:
          'Our team can design the overall architecture and configuration of the energy system based on your needs and goals. This includes identifying suitable energy sources, selecting appropriate equipment and technologies, as well as planning energy storage and distribution systems.',
      },
      {
        icon: 'cloud_icon_2',
        title: 'Optimization',
        content:
          'We conduct energy efficiency assessments, analyze energy usage patterns, and provide energy-saving optimization recommendations.',
      },
      {
        icon: 'cloud_icon_3',
        title: 'Feasibility Study',
        content:
          "Before the design and implementation of the system, a feasibility study can be conducted to assess the project's feasibility and economic viability. This includes conducting technical assessments, energy resource evaluations, cost-benefit analyses, and more in order to assist your decision-making.",
      },
      {
        icon: 'cloud_icon_4',
        title: 'Tech Support',
        content:
          'We provide technical consulting services to address questions regarding renewable energy system technologies, equipment selection, energy storage, and management',
      },
      {
        icon: 'cloud_icon_4',
        title: 'Supervision and Permitting',
        content:
          'We assist in handling the construction and regulatory affairs of energy systems. This includes obtaining necessary permits and approvals, communicating with relevant organizations and authorities, and ensuring project compliance and safety.',
      },
      {
        icon: 'cloud_icon_4',
        title: 'Performance Assessment',
        content:
          'We provide remote monitoring and assessment to monitor the performance of renewable energy systems. This helps in identifying issues, conducting troubleshooting, and ensuring efficient system operation.',
      },
    ],
  },
  es: {
    title:
      'Nuestro objetivo es proporcionar orientación y apoyo profesional en el proceso de diseño e implementación de sistemas energéticos, asegurando el éxito y maximizando los beneficios de los proyectos.',
    content:
      'Según requisitos específicos, puedes elegir los elementos de servicio correspondientes y colaborar con nuestro equipo de profesionales relevantes.',
    list: [
      {
        icon: 'cloud_icon_1',
        title: 'Diseño',
        content:
          'Nuestro equipo puede diseñar la arquitectura general y la configuración del sistema energético según tus necesidades y objetivos. Esto incluye la identificación de fuentes de energía adecuadas, la selección de equipos y tecnologías apropiados, así como la planificación de sistemas de almacenamiento y distribución de energía.',
      },
      {
        icon: 'cloud_icon_2',
        title: 'Optimización',
        content:
          'Realizamos evaluaciones de eficiencia energética, analizamos los patrones de consumo de energía y proporcionamos recomendaciones de optimización para el ahorro de energía.',
      },
      {
        icon: 'cloud_icon_3',
        title: 'Estudio de Viabilidad',
        content:
          'Antes del diseño e implementación del sistema, se puede realizar un estudio de viabilidad para evaluar la viabilidad y viabilidad económica del proyecto. Esto incluye realizar evaluaciones técnicas, evaluaciones de recursos energéticos, análisis costo-beneficio, y más para ayudar en la toma de decisiones.',
      },
      {
        icon: 'cloud_icon_4',
        title: 'Soporte Técnico',
        content:
          'Proporcionamos servicios de consultoría técnica para abordar preguntas sobre tecnologías de sistemas de energía renovable, selección de equipos, almacenamiento de energía y gestión.',
      },
      {
        icon: 'cloud_icon_4',
        title: 'Supervisión y Permisos',
        content:
          'Asistimos en el manejo de la construcción y asuntos regulatorios de los sistemas energéticos. Esto incluye la obtención de permisos y aprobaciones necesarios, comunicación con organizaciones y autoridades relevantes, y garantizar el cumplimiento y la seguridad del proyecto.',
      },
      {
        icon: 'cloud_icon_4',
        title: 'Evaluación de Rendimiento',
        content:
          'Proporcionamos monitoreo y evaluación remotos para supervisar el rendimiento de los sistemas de energía renovable. Esto ayuda a identificar problemas, realizar solución de problemas y garantizar un funcionamiento eficiente del sistema.',
      },
    ],
  },
  cn: {
    title: '我们的目标是在能源系统的设计和实施过程中提供专业的指导和支持，确保项目的成功和效益最大化。',
    content: '根据具体的需求，您可以选择相应的服务项目，并与我们相关专业团队合作。',
    list: [
      {
        icon: 'cloud_icon_1',
        title: '系统设计',
        content:
          '我们的团队可以根据您的需求和目标，设计能源系统的整体构架和配置。这包括确定适合的能源来源、选择适当的设备和技术，以及规划能源储存和分配系统。',
      },
      {
        icon: 'cloud_icon_2',
        title: '节能优化',
        content: '进行能源效率评价，分析能源使用模式，并提供节能优化建议。',
      },
      {
        icon: 'cloud_icon_3',
        title: '可行性研究',
        content:
          '在系统的设计和实施之前，可进行可行性研究，以评价项目的可行性和经济性。这包括进行技术评价、能源资源评价、成本效益分析等，以帮助客户做出明智的决策。',
      },
      {
        icon: 'cloud_icon_4',
        title: '技术咨询',
        content: '提供技术咨询服务，回答关于绿能系统技术、设备选择、能源储存和管理等方面的问题。',
      },
      {
        icon: 'cloud_icon_4',
        title: '建设监管',
        content:
          '协助处理能源系统的建设和监管事务。这包括获取必要的许可证和批文、与相关机构和当局沟通、确保项目的合规性和安全性。',
      },
      {
        icon: 'cloud_icon_4',
        title: '性能监测',
        content:
          '提供远程监测和性能评价服务，监控绿能系统的运行状态和性能表现。这有助于发现问题、进行故障排除，并确保系统的高效运行。',
      },
    ],
  },
}
