export const data = {
  en: {
    title: 'Financial Service (Coming Soon)',
    content:
      'We offer flexible leasing services to help clients obtain reliable energy solutions without the need for upfront investment. Additionally, we provide comprehensive financial loan services to cater to the needs of different businesses.',
    list: [
      {
        icon: 'financial_icon_1',
        title: 'Leasing Service',
        content:
          'For businesses or individuals, leasing renewable energy systems can be an attractive option. Leasing can reduce the burden of initial investment and cash flow while providing more flexible payment options',
      },
      {
        icon: 'financial_icon_2',
        title: 'Banking Doc or Gov Permits',
        content:
          'This includes completing specific forms and submitting necessary documents and supporting materials such as project proposals, engineering plans, environmental impact assessment reports, and more.',
      },
      {
        icon: 'financial_icon_3',
        title: 'Early Bird Planning',
        content:
          'Plan ahead for the application timeline, ensuring sufficient time to gather necessary documents and avoid delays or rejections.',
      },
    ],
  },
  es: {
    title: 'Servicio Financiero (Próximamente)',
    content:
      'Ofrecemos servicios de arrendamiento flexibles para ayudar a los clientes a obtener soluciones energéticas confiables sin la necesidad de inversión inicial. Además, proporcionamos servicios de préstamos financieros completos para satisfacer las necesidades de diferentes empresas.',
    list: [
      {
        icon: 'financial_icon_1',
        title: 'Servicio de Arrendamiento',
        content:
          'Para empresas o individuos, el arrendamiento de sistemas de energía renovable puede ser una opción atractiva. El arrendamiento puede reducir la carga de la inversión inicial y del flujo de efectivo, al mismo tiempo que proporciona opciones de pago más flexibles.',
      },
      {
        icon: 'financial_icon_2',
        title: 'Documentos Bancarios o Permisos Gubernamentales',
        content:
          'Esto incluye completar formularios específicos y presentar documentos necesarios y materiales de apoyo como propuestas de proyectos, planes de ingeniería, informes de evaluación de impacto ambiental, y más.',
      },
      {
        icon: 'financial_icon_3',
        title: 'Planificación Temprana',
        content:
          'Planificando con anticipación el cronograma de solicitud, asegurando tiempo suficiente para reunir los documentos necesarios y evitar retrasos o rechazos.',
      },
    ],
  },
  cn: {
    title: '融资与租赁服务（规划中）',
    content:
      '我们提供灵活的储能设备租赁服务，帮助客户在没有购买储能系统的资金的情况下获得可靠的能源解决方案。同时提供一站式金融贷款服务，以满足不同企业的需求。',
    list: [
      {
        icon: 'financial_icon_1',
        title: '租赁选项',
        content:
          '对于企业或个人而言，租赁绿能系统可能是一种具有吸引力的选项。租赁可以减少初始投资和现金流的压力，并提供更灵活的支付方式。',
      },
      {
        icon: 'financial_icon_2',
        title: '协同办理银行或是政府相关文件',
        content:
          '这包括填写具体表格并提交必要的文件和支持材料，如项目建议书、工程计划、环境影响评估报告等。',
      },
      {
        icon: 'financial_icon_3',
        title: '时效性和准确性',
        content:
          '预先计划申请时间，确保足够的时间来收集必要的文件。确保申请文件的时效性和准确性，以避免延误或申请被拒绝。',
      },
    ],
  },
}
