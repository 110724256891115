import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Styles from './style.module.scss'
import { Image } from 'react-bootstrap'
import ImageCover from '../image-cover'
import ClassName from 'clsx'

export default function Index(props) {
  const navigate = useNavigate()
  const [hover, setHover] = useState(false)
  const { id, image, date, title } = props

  if (!id) return null

  return (
    <div
      className={ClassName(Styles.card, hover && Styles.active)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => navigate(`/news-detail?id=${id}`)}
    >
      <ImageCover className={Styles.image_cover} src={`image/${image}`} />
      <div className={Styles.content}>
        <div className={Styles.top}>
          {date && <span className={Styles.time}>{date}</span>}
          <div className={Styles.arrow}>
            {hover && <Image src='assets/arrow_right.png' />}
            {!hover && <Image src='assets/arrow_right_white.png' />}
          </div>
        </div>
        {title && <div className={Styles.title}>{title}</div>}
      </div>
    </div>
  )
}
