import { useState } from 'react'
import ImageCover from '@/components/image-cover'
import Styles from './style.module.scss'
import ClassName from 'clsx'
import { solution_data as _solution_data } from '@/utils/data'
import { useNavigate } from 'react-router-dom'
import useLang from '@/utils/hooks/use-lang'

const btnText = {
  en: 'View Details',
  es: 'Ver Detalles',
  cn: '查看详情',
}

const titleText = {
  en: 'Solutions we offer ..',
  es: 'Soluciones que ofrecemos…',
  cn: '我们主要的产品和服务',
}

export default function Index() {
  const navigate = useNavigate()
  const [active, setActive] = useState(0)
  const [lang] = useLang()
  const solution_data = _solution_data[lang]

  return (
    <div className={Styles.container}>
      <div className={Styles.title}>{titleText[lang]}</div>
      <div className={Styles.tab_wrapper}>
        <div className={Styles.tabs}>
          {solution_data.map((d, i) => (
            <div
              key={d.id}
              className={ClassName(Styles.tabs_item, active === i && Styles.active)}
              onClick={() => setActive(i)}
            >
              {d.title}
            </div>
          ))}
        </div>
        <div className={Styles.main}>
          <div className={Styles.content_title}>{solution_data[active].title}</div>
          <div className={Styles.content}>{solution_data[active].content}</div>
          <div className={Styles.view_detail} onClick={() => navigate(solution_data[active].path)}>
            {btnText[lang]}
          </div>
        </div>
        <div className={Styles.right}>
          <ImageCover className={Styles.image_cover} src={solution_data[active].image_url} />
        </div>
      </div>
    </div>
  )
}
