import React from 'react'
import { useNavigate, Outlet } from 'react-router-dom'
import Styles from './style.module.scss'
import UseScrollTop from '@/utils/hooks/use-scroll-top'

// const renderChildren = (ready, children) => {
//   if (!ready) {
//     return <div>loading...</div>
//   }
//   return children || <div>welcome</div>
// }

export default function Index(props) {
  const navigate = useNavigate()
  const { ready, children } = props
  
  UseScrollTop()

  return (
    <div className='Index'>
      <div className={Styles.content}>
        <Outlet cn='en' />
      </div>
    </div>
  )
}
