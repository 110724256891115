export const data = {
  en: [
    {
      icon: 'assets/energy.png',
      icon_white: 'assets/energy_white.png',
      cover: 'assets/pic_cover_1.png',
      image: 'assets/pic_banner_1_dark.png',
      title: 'Agriculture',
      list: [
        {
          label: 'Applications',
          content: [
            'Solar Powered Farming & Greenhouse',
            'Solar Powered Cold Storage',
            'Electric Equipment/Vehicle Charging',
            'Backup Power & Power back to Grid',
          ],
        },
        {
          label: 'Benefits',
          content: [
            'Greener business profile. Drive the advancement of sustainable agriculture',
            'Long-term investment returns while aligning with government policies',
            'Reduce energy costs & lower maintenance expenses with efficient solutions',
            'Expand the scope of operations',
          ],
        },
      ],
    },
    {
      icon: 'assets/efficiency.png',
      icon_white: 'assets/efficiency_white.png',
      cover: 'assets/pic_cover_2.png',
      image: 'assets/pic_banner_2_dark.png',
      title: 'Winery',
      list: [
        {
          label: 'Applications',
          content: [
            'Solar Powered Wine Production & Storage',
            'Renewable Powered Visitor Facilities',
            'EV Charging Station',
            'On-site Residence powered by renewables',
          ],
        },
        {
          label: 'Benefits',
          content: [
            'High efficiency & stability in electricity supply',
            'Optimize energy costs, minimize O&M expenses',
            'Seamless integration for sustainable long-term investment returns',
            'Expand operational horizons for business growth',
          ],
        },
      ],
    },
    {
      icon: 'assets/sustainable.png',
      icon_white: 'assets/sustainable_white.png',
      cover: 'assets/pic_cover_3.png',
      image: 'assets/pic_banner_3_dark.png',
      title: 'Public & Commercial Building',
      list: [
        {
          label: 'Applications',
          content: [
            'Solar Powered Building Operations',
            'Battery Storage for Peak Shaving',
            'Solar Roof Carport & EV Charging',
            'Back Up Power & Power back to Grid',
          ],
        },
        {
          label: 'Benefits',
          content: [
            'Elevate corporate image and social impact',
            'Flexible and reliable power supply with scalability',
            'Enhancing long-term business viability and profitability',
            'Achieving regulatory compliance, promoting sustainable development',
          ],
        },
      ],
    },
    {
      icon: 'assets/sustainable.png',
      icon_white: 'assets/sustainable_white.png',
      cover: 'assets/pic_cover_4.png',
      image: 'assets/pic_banner_4_dark.png',
      title: 'Electric Vehicle Charging Station',
      list: [
        {
          label: 'Applications',
          content: [
            'EV Charging with renewable energy',
            'Battery Storage System for peak-shaving & load-balancing',
            'Solar Roof Carport',
            'Power back to Grid & V2G potentials',
          ],
        },
        {
          label: 'Benefits',
          content: [
            'Empowering business with a clean energy supply including the adaptability for system scale-up',
            'Aligning with government policies to support sustainable development',
            'Optimize energy costs and minimize O&M expenses with smart solutions',
            'Boosting competitiveness and maximizing long-term business performance',
          ],
        },
      ],
    },
    {
      icon: 'assets/sustainable.png',
      icon_white: 'assets/sustainable_white.png',
      cover: 'assets/pic_cover_5.png',
      image: 'assets/pic_banner_5_dark.png',
      title: 'Microgrid',
      list: [
        {
          label: 'Applications',
          content: [
            'Self-sufficient Microgrid',
            'Energy-sharing, Demand-response Community',
            'EV Charging (Load Balancing)',
            'Microgrid as a Service',
          ],
        },
        {
          label: 'Benefits',
          content: [
            'Reliable and independent energy supply system',
            'Mitigate transmission losses and optimize maintenance costs',
            'Modular design allows simple and flexible system scale-up',
            'Integration with other renewable energy sources, providing a comprehensive and unified system',
          ],
        },
      ],
    },
  ],
  es: [
    {
      icon: 'assets/energy.png',
      icon_white: 'assets/energy_white.png',
      cover: 'assets/pic_cover_1.png',
      image: 'assets/pic_banner_1_dark.png',
      title: 'Agricultura',
      list: [
        {
          label: 'Aplicaciones',
          content: [
            'Agricultura e Invernaderos con Energía Solar',
            'Almacenamiento Frigorífico con Energía Solar',
            'Carga de Equipos/Vehículos Eléctricos',
            'Energía de Respaldo y Devolución de Energía a la Red',
          ],
        },
        {
          label: 'Beneficios',
          content: [
            'Perfil empresarial más verde. Impulsa el avance de la agricultura sostenible.',
            'Retornos de inversión a largo plazo alineados con las políticas gubernamentales.',
            'Reducir los costos energéticos y disminuir los gastos de mantenimiento con soluciones eficientes.',
            'Ampliar el alcance de las operaciones.',
          ],
        },
      ],
    },
    {
      icon: 'assets/efficiency.png',
      icon_white: 'assets/efficiency_white.png',
      cover: 'assets/pic_cover_2.png',
      image: 'assets/pic_banner_2_dark.png',
      title: 'Bodega Vinícola',
      list: [
        {
          label: 'Aplicaciones',
          content: [
            'Producción y Almacenamiento de Vino con Energía Solar',
            'Instalaciones para Visitantes con Energía Renovable',
            'Estación de Carga para Vehículos Eléctricos',
            'Residencia en el Sitio Alimentada por Energías Renovables',
          ],
        },
        {
          label: 'Beneficios',
          content: [
            'Alta eficiencia y estabilidad en el suministro de electricidad',
            'Optimizar los costos de energía, minimizar los gastos de operación y mantenimiento',
            'Integración fluida para rendimientos de inversión sostenibles a largo plazo',
            'Expandir los horizontes operativos para el crecimiento empresarial.',
          ],
        },
      ],
    },
    {
      icon: 'assets/sustainable.png',
      icon_white: 'assets/sustainable_white.png',
      cover: 'assets/pic_cover_3.png',
      image: 'assets/pic_banner_3_dark.png',
      title: 'Edificios Públicos y Comerciales',
      list: [
        {
          label: 'Aplicaciones',
          content: [
            'Operaciones de Edificios con Energía Solar',
            'Almacenamiento de Baterías para Reducción de la Demanda Máxima',
            'Estacionamiento Techado con Techo Solar y Carga para Vehículos Eléctricos (VE)',
            'Energía de Respaldo y Devolución de Energía a la Red',
          ],
        },
        {
          label: 'Beneficios',
          content: [
            'Elevar la imagen corporativa y el impacto social.',
            'Suministro de energía flexible y confiable con escalabilidad.',
            'Mejorar la viabilidad a largo plazo y la rentabilidad del negocio.',
            'Lograr el cumplimiento normativo y promover el desarrollo sostenible.',
          ],
        },
      ],
    },
    {
      icon: 'assets/sustainable.png',
      icon_white: 'assets/sustainable_white.png',
      cover: 'assets/pic_cover_4.png',
      image: 'assets/pic_banner_4_dark.png',
      title: 'Estación de Carga de Vehículos Eléctricos',
      list: [
        {
          label: 'Aplicaciones',
          content: [
            'Carga de vehículos eléctricos con energía renovable.',
            'Sistema de almacenamiento de baterías para reducción de la demanda máxima y equilibrio de carga.',
            'Estacionamiento con Techo Solar',
            'Devolver energía a la red y potenciales de V2G (Vehicle-to-Grid).',
          ],
        },
        {
          label: 'Beneficios',
          content: [
            'Potenciar el negocio con un suministro de energía limpia, incluida la adaptabilidad para la ampliación del sistema.',
            'Alinear con políticas gubernamentales para apoyar el desarrollo sostenible.',
            'Optimizar los costos energéticos y minimizar los gastos de operación y mantenimiento con soluciones inteligentes.',
            'Impulsar la competitividad y maximizar el rendimiento a largo plazo del negocio.',
          ],
        },
      ],
    },
    {
      icon: 'assets/sustainable.png',
      icon_white: 'assets/sustainable_white.png',
      cover: 'assets/pic_cover_5.png',
      image: 'assets/pic_banner_5_dark.png',
      title: 'Microred',
      list: [
        {
          label: 'Aplicaciones',
          content: [
            'Microred autosuficiente',
            'Comunidad de Compartición de Energía y Respuesta a la Demanda',
            'Carga de Vehículos Eléctricos (Equilibrio de Carga)',
            'Microred como Servicio',
          ],
        },
        {
          label: 'Beneficios',
          content: [
            'Sistema de suministro de energía confiable e independiente.',
            'Mitigar pérdidas de transmisión y optimizar costos de mantenimiento.',
            'El diseño modular permite una ampliación del sistema simple y flexible.',
            'Integración con otras fuentes de energía renovable, proporcionando un sistema integral y unificado.',
          ],
        },
      ],
    },
  ],
  cn: [
    {
      icon: 'assets/energy.png',
      icon_white: 'assets/energy_white.png',
      cover: 'assets/pic_cover_1.png',
      image: 'assets/pic_banner_1_dark.png',
      title: '农场和农业用途',
      list: [
        '建立绿色形象',
        '有效节约能源成本以及降低维护成本',
        '促进可持续农业',
        '扩展农场经营范围 创造更多发展和商机',
        '长期投资回报＆支持政府政策',
      ],
    },
    {
      icon: 'assets/efficiency.png',
      icon_white: 'assets/efficiency_white.png',
      cover: 'assets/pic_cover_2.png',
      image: 'assets/pic_banner_2_dark.png',
      title: '酒庄和葡萄园',
      list: [
        '自给自足与稳定的能源供应',
        '独立与稳定的电力供应',
        '减少能源成本以及降低维护成本',
        '扩展经营范围 创造更多发展和商机',
        '整合再生能源与长期投资回报',
      ],
    },
    {
      icon: 'assets/sustainable.png',
      icon_white: 'assets/sustainable_white.png',
      cover: 'assets/pic_cover_3.png',
      image: 'assets/pic_banner_3_dark.png',
      title: '公共及商业建筑',
      list: [
        '提升企业形象和社会影响力',
        '弹性与稳定的电力供应与系统升级',
        '减少能源成本以及降低维护成本',
        '满足法规要求和可持续性发展',
        '提升长期经营效益',
      ],
    },
    {
      icon: 'assets/sustainable.png',
      icon_white: 'assets/sustainable_white.png',
      cover: 'assets/pic_cover_4.png',
      image: 'assets/pic_banner_4_dark.png',
      title: '电动汽车充电站',
      list: [
        '清洁能源供给与可拓展性',
        '建立绿色形象和提升品牌价值',
        '配合政府策略与可持续行发展',
        '减少能源成本以及降低维护成本',
        '提升竞争优势与经营效益',
      ],
    },
    {
      icon: 'assets/sustainable.png',
      icon_white: 'assets/sustainable_white.png',
      cover: 'assets/pic_cover_5.png',
      image: 'assets/pic_banner_5_dark.png',
      title: '独立小型电网',
      list: [
        '可靠与独立的能源供给',
        '避免传输损失降低维护成本',
        '高度适应性与模块化系统升级',
        '系统性整合其他再生能源',
        '减少碳足迹与可持续性经营',
      ],
    },
  ],
}

export const titleText = {
  en: 'Applications & Advantages',
  es: 'Applications & Advantages',
  cn: '用途＆优势',
}
